import React from 'react';
import CSS from './index.module.scss';
import { LiraAdvContainer } from '@sbt-web/adv';
import { LiraContainerIds } from '../../helpers/adv/lira/liraContainerIds';

export const AdvGoogleBox = function () {
  return (
    <section className="vertical-block">
      <div className={CSS['box_homepage']} id="box_homepage">
        <LiraAdvContainer
          idForAd={LiraContainerIds.belowTheFold}
          className={CSS['box_homepage_inner']}
        />
      </div>
    </section>
  );
};
