import type { HebeModel } from '@sbt-web/adv';

const advFallbackConfigDesktop: HebeModel = {
  name: 'home',
  page_level_settings: {},
  slots: [
    {
      //Ex: dfp_box_homepage_desktop
      container_id: 'home-belowthefold',
      name: 'the slot below the fold',
      configurations: {
        gam: [
          {
            sequence: 0,
            unit_id: '/11825272/hp/subito-desktop-hp-banner',
            sizes: ['fluid'],
          },
        ],
      },
    },
    {
      //Ex: dfp_overlay_homepage
      container_id: 'home-skin',
      name: 'the home page skin',
      configurations: {
        gam: [
          {
            sequence: 0,
            unit_id: '/11825272/hp/subito-desktop-hp-outofpage',
            sizes: ['1x1'],
          },
        ],
      },
    },
    {
      //Ex: dfp_box_homepage_desktop
      container_id: 'home-abovethefold',
      name: 'the slot above the fold',
      configurations: {
        gam: [
          {
            sequence: 0,
            unit_id: '/11825272/hp/subito-desktop-hp-top',
            sizes: ['970x250'],
            refresh: {
              enabled: true,
              interval: 30000,
            },
          },
        ],
      },
    },
  ],
};

export { advFallbackConfigDesktop };
