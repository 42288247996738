import type { HebeModel } from '@sbt-web/adv';

const advFallbackConfigMsite: HebeModel = {
  name: 'home',
  page_level_settings: {},
  slots: [
    {
      //Ex: dfp_box_homepage_desktop
      container_id: 'home-belowthefold',
      name: 'the slot below the fold',
      configurations: {
        gam: [
          {
            sequence: 0,
            unit_id: '/11825272/hp/subito-msite-hp-banner',
            sizes: ['fluid', '320x100'],
          },
        ],
      },
    },
    {
      //Ex: dfp_overlay_homepage
      container_id: 'home-skin',
      name: 'the home page skin',
      configurations: {
        gam: [
          {
            sequence: 0,
            unit_id: '/11825272/hp/subito-msite-hp-outofpage',
            sizes: ['1x1'],
          },
        ],
      },
    },
  ],
};

export { advFallbackConfigMsite };
